export const header = {
  hellobar1: "[Latest] Every 1.2mins a Wipro employee stood proudly recognised.",
  hellobar2:
    ": Discover Wipro’s R&R Program for 230,000 employees!",
  hellobar3: "",
  hellobarcta: "Download the Case Study",
  helloBarNA1: "[Latest] Every 1.2mins a Wipro employee stood proudly recognised.",
  helloBarNA2: ": Discover Wipro’s R&R Program for 230,000 employees!",
  hellobarctaNA: "Download the Case Study",
  "Watch Video": "Watch Video",
  Home: "Home",
  "About us": "About us",
  "Contact us": "Contact us",
  "Download app": "Download app",
  "Sign in": "Sign in",

  Solutions: "Solutions",
  Pricing: "Pricing",
  Clients: "Clients",
  Partners: "Partners",
  Resources: "Resources",
  "Existing customer": "Existing customer",

  "Vantage Rewards": "Vantage Rewards",
  "Employee Rewards and Recognition.": "Employee Rewards and Recognition.",
  "Vantage Perks": "Vantage Perks",
  "Employee Discount and Benefits.": "Employee Discount and Benefits.",
  "Vantage Pulse": "Vantage Pulse",
  "Employee Survey and Feedback.": "Employee Survey and Feedback.",
  "Vantage Fit": "Vantage Fit",
  "Employee Health and Wellness.": "Employee Health and Wellness.",
  "All-in-One": "All-in-One",
  "Boost employee engagement with our AI-powered solutions":
    "Boost employee engagement with our AI-powered solutions",
  Integrations: "Integrations",
  "Seamless integrations with your existing HCM/HRIS platform.":
    "Seamless integrations with your existing HCM/HRIS platform.",
  "Watch Product Videos": "Watch Product Videos",
  "All-in-One Solution": "All-in-One Solution",
  "Employee Recognition Platform": "Employee Recognition Platform",
  "Employee Pulse Survey": "Employee Pulse Survey",
  "Corporate Wellness Platform": "Corporate Wellness Platform",
  "Employee Benefits Platform": "Employee Benefits Platform",

  "Clients Overview": "Clients Overview",
  "More than 400+ companies are enhancing their employee experience and building an engaged and happy workforce with us.":
    "More than 400+ companies are enhancing their employee experience and building an engaged and happy workforce with us.",
  "Success Stories": "Success Stories",
  "Read our client success stories and learn how organisations are using Vantage Circle to build an engaged and productive workplace.":
    "Read our client success stories and learn how organisations are using Vantage Circle to build an engaged and productive workplace.",
  "Top Success Stories": "Top Success Stories",
  "Ingram Micro Witnessed an 8% Increase in Employee Engagement with Vantage Rewards in 2020":
    "Ingram Micro Witnessed an 8% Increase in Employee Engagement with Vantage Rewards in 2020",
  "Accor improved its Employee Recognition by 53% through Vantage Circle":
    "Accor improved its Employee Recognition by 53% through Vantage Circle",
  "Vantage Circle helped TATA Motors in achieving 82% rise in peer to peer recognition":
    "Vantage Circle helped TATA Motors in achieving 82% rise in peer to peer recognition",

  Blog: "Blog",
  "Insightful articles, best practices and trends in HR innovation":
    "Insightful articles, best practices and trends in HR innovation",
  "Guides and eBooks": "Guides and eBooks",
  "A free resources center with practical guides on HR management.":
    "A free resources center with practical guides on HR management.",
  "Influencers Podcast": "Influencers Podcast",
  "Listen to thought leaders on best HR practices and trends.":
    "Listen to thought leaders on best HR practices and trends.",
  "Doers Webinar": "Doers Webinar",
  "Watch our webinar series to get practical lessons on employee engagement strategies.":
    "Watch our webinar series to get practical lessons on employee engagement strategies.",
  "Recent Posts": "Recent Posts",
  "7 Techniques for Employee Stress Management in the workplace":
    "7 Techniques for Employee Stress Management in the workplace",
  "5 Easy Steps To Build A Successful Severance Pay Plan":
    "5 Easy Steps To Build A Successful Severance Pay Plan",
  "30 Email Etiquette Rules Every HR Should Know":
    "30 Email Etiquette Rules Every HR Should Know",
  "Transactional Leadership: Meaning, Pros & Cons, Relevance":
    "Transactional Leadership: Meaning, Pros & Cons, Relevance",
  "View all posts": "View all posts"
}
